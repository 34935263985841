import { Routes, Route } from 'react-router-dom';
import './App.css';
import Welcome from './pages/Welcome';
import Home from './pages/Home';
import Footer from './components/Footer';

function App() {
  return (
    <div className='lg:bg-waterfall bg-syringe bg-top bg-cover bg-no-repeat h-screen w-screen'>
      <div className="App bg-black/30 lg:bg-black/80 bg-cover h-screen w-screen">
        <Routes>
          <Route path="/" element={<Welcome />}/>
          <Route path="/home" element={<Home />}/>
        </Routes>
      <Footer />
      </div>
    </div>
    
  );
}

export default App;
