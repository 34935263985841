import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='text-xl ml-2 mb-2 lg:text-3xl text-center text-white fixed bottom-0 lg:ml-6 lg:mb-6'>
            &copy; 2022 RX CLUB | ALL RIGHTS RESERVED
        </div>
    )
}

export default Footer;
