import React from 'react';
import { Link } from "react-router-dom"

const Welcome = () => {
    return (
        <div className='flex flex-col justify-between'>
            <div className='flex flex-col'>
                <div className='hidden lg:contents text-7xl mt-14 lg:text-9xl font-bold text-center text-violet-500 lg:mt-12'>
                    WELCOME TO
                </div>
                <div className='invisible lg:visible bg-logo w-80 h-40 lg:w-[700px] lg:h-[300px] bg-center bg-cover bg-no-repeat mx-auto'></div>
            </div>

            <Link className="text-6xl mt-[370px] font-bold lg:text-5xl text-white hover:text-violet-600 lg:my-20" to="/home">
                    CLICK TO ENTER
            </Link>
        </div>
    );
};

export default Welcome;