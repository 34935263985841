import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
    return (
        <div className='flex flex-col'>
            <div className='text-7xl my-14 lg:text-9xl text-violet-600 font-bold text-center lg:my-8'>PRODUCTS</div>
        </div>
    )
}

export default Home
